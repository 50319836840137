<template>
  <div class="" v-if="data">
    <div
      v-for="(item, index) in data.attribute_groups"
      :key="index"
      class="md:w-full md:py-0"
    >
      <div
        class="flex items-center justify-center bg-agrogo-green-3 h-8 px-6 lg:h-12 lg:px-12"
      >
        <a
          class="flex items-center font-dinpro font-medium text-agrogo-yellow-1 text-lg lg:text-xl"
          ><span class="leading-none pr-2 lg:pr-3" :class="item.icon"></span>
          {{ item.name }}</a
        >
      </div>
      <template v-for="att in item.attribute" :key="att.id">
        <item-topography
          :item="att"
          v-if="item.id != 5 && att.type_attributes_input.id == 29"
        />
        <item-description
          :item="att"
          v-else-if="item.id != 5 && att.id == 21"
        />
        <item-list
          :item="att"
          v-else-if="
            (item.id != 5 && att.type_attributes_input.name == 'Checkbox') ||
            att.type_attributes_input.name == 'Select-multiple' ||
            att.type_attributes_input.name == 'Select'
          "
        />
        <item-height
          :item="att"
          v-else-if="
            item.id != 5 && att.type_attributes_input.name == 'From-To'
          "
        />
        <Item :item="att" v-else-if="item.id != 5" />
      </template>
      <item-table v-if="item.id == 5" :item="item" />
    </div>
  </div>
</template>

<script>
import Item from "./Item";
import ItemTopography from "./ItemTopography.vue";
import ItemDescription from "./ItemDescription.vue";
import ItemList from "./ItemList.vue";
import ItemHeight from "./ItemHeight";
import ItemTable from "./ItemTable";

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    Item,
    ItemTopography,
    ItemDescription,
    ItemList,
    ItemHeight,
    ItemTable,
  },
};
</script>
