<template>
  <div
    class="flex relative overflow-hidden rounded-t-2xl md:rounded-t-30 justify-center mt-0"
  >
    <div class="flex w-full h-auto relative" v-if="record2">
      <img
        v-if="record2.type == 1"
        class="w-full video-detail rounded-t-xl"
        :src="record2.urlImg"
        loading="lazy"
        data-src="@/assets/images/loading.gif"
        data-srcset="@/assets/images/loading.gif"
        alt="Agrogo Fincas"
      />
      <video
        v-else
        class="w-full rounded-t-xl video-detail bg-agrogo-green-2"
        preload="auto"
        poster="@/assets/images/loading.gif"
        controls
        autoplay
        muted
        loop
        playsinline
        id="video-detail"
      >
        <source src="" class="w-full" type="video/mp4" />
      </video>
      <!-- <div class="bg-agrogo-green-7 rounded-tl-xl">
        <p class="font-nexa font-normal text-xs text-white">Plus</p>
        <p class="text-agrogo-green-6 text-xs space-x-px">
          <span class="icon-star2"></span><span class="icon-star2"></span
          ><span class="icon-star2"></span><span class="icon-star2"></span
          ><span class="icon-star2 text-agrogo-green-5"></span>
        </p>
      </div> -->
      <button
        @click.prevent="toggleFullScreen()"
        v-if="record2.type == 2"
        class="flex items-center justify-center w-11 h-10 bg-transparent absolute rounded-bl-3xl rounded-tr-xl left-24 top-0"
      >
        <img src="@/assets/images/fullscreen.png" alt="Agrogo Fincas" />
      </button>
      <!--No favorito-->
      <div v-if="isFavorite != null">
        <button
          v-if="isFavorite == 0"
          @click.prevent="addDeleteFarm(1)"
          class="flex items-center justify-center w-11 h-10 bg-agrogo-yellow-1 absolute rounded-bl-3xl rounded-tr-xl right-0 top-0"
        >
          <span
            class="icon-heart text-base text-white hover:text-agrogo-green-2"
          ></span>
        </button>
        <!--favorito-->
        <button
          v-else
          @click.prevent="addDeleteFarm(0)"
          class="flex items-center justify-center w-11 h-10 bg-agrogo-yellow-1 absolute rounded-bl-3xl rounded-tr-xl right-0"
        >
          <span class="icon-heart text-base text-agrogo-green-1"></span>
        </button>
      </div>
      <div v-if="dataFarm.priority.length > 0">
        <div
          class="w-24 h-10 bg-agrogo-green-6 absolute rounded-br-2xl rounded-tl-xl left-0 top-0"
        >
          <p class="font-nexa font-regular mt-1 text-sm text-white">
            {{ textFarm(dataFarm.priority[0]) }}
          </p>
          <p class="text-agrogo-green-6 size-star space-x-px">
            <span
              class="icon-star2"
              v-for="(start, i) in starts"
              :key="i"
              :class="{
                'text-agrogo-green-6': start.isActive,
                'text-agrogo-green-5': !start.isActive,
              }"
            />
            <!-- <span class="icon-star2"></span><span class="icon-star2"></span
            ><span class="icon-star2"></span><span class="icon-star2"></span
            ><span class="icon-star2 text-agrogo-green-5"></span> -->
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- CARRUSEL IMAGENES -->
  <div
    class="w-auto h-auto bg-agrogo-green-2 md:bg-agrogo-white md:py-2"
    v-if="record"
  >
    <div class="items-center px-2 py-2 overflow-x-scroll flex space-x-2">
      <div
        v-for="(item, index) in records"
        :key="index"
        :id="'card' + item.id"
        class="card-carousel w-12 h-12 lg:w-16 lg:h-16 hover:no-underline"
        @click.prevent="findRecord(item)"
      >
        <img
          class="w-full h-full"
          :src="item?.small_url ? item?.small_url : item?.urlImg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import alertMessageMixin from "@/mixins/alertMessageMixin";
import { mapState } from "vuex";

export default {
  emits: ["addDeleteFarm"],
  mixins: [alertMessageMixin],
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: () => {},
    },
    dataFarm: {
      type: Object,
      default: null,
    },
    isFavorite: {
      default: 0,
    },
  },
  data() {
    return {
      starts: [
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
      record2: null,
    };
  },
  watch: {
    dataFarm() {
      const me = this;
      me.starts.forEach((el) => (el.isActive = false));
      me.fillStart(me.dataFarm);
    },
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  methods: {
    findRecord(item) {
      const me = this;
      const card = document.querySelectorAll(".card-carousel");
      const cardId = document.getElementById("card" + item.id);

      card.forEach((el) => {
        el.classList.forEach((x) => {
          if (x === "active-card-img") el.classList.remove("active-card-img");
        });
      });

      if (cardId) {
        cardId.classList.forEach((x) => {
          x === "active-card-img"
            ? cardId.classList.remove("active-card-img")
            : cardId.classList.add("active-card-img");
        });
      }

      me.record2 = item;
      if (me.record2.type == 2) {
        setTimeout(() => {
          me.loadVideo();
        }, 100);
      }
    },
    //AGREGAR O ELIMINAR FAVORITOS
    addDeleteFarm(favority) {
      const me = this;
      if (me.isAuthenticated) {
        me.$emit("addDeleteFarm", favority);
      } else {
        me.alertMenssage(
          "Para poder agregar esta finca a tus favoritas debes iniciar sesión",
          6000
        );
      }
    },
    getImage(media) {
      let url = null;
      if (media) {
        url = `${process.env.VUE_APP_AWS_URL}` + media.small_url;
      } else {
        url = require("@/assets/images/previewimg.png");
      }

      return url;
    },
    fillStart(item) {
      const me = this;
      if (item.priority.length > 0) {
        if (item.priority[0].id == 1 || item.priority[0].id == 2) {
          // Super
          me.starts.forEach((el) => {
            el.isActive = true;
          });
        } else if (item.priority[0].id == 3) {
          // Plus
          me.starts.forEach((el, index) => {
            if (index <= 3) {
              el.isActive = true;
            }
          });
        } else if (item.priority[0].id == 4) {
          // Alto
          me.starts.forEach((el, index) => {
            if (index <= 2) {
              el.isActive = true;
            }
          });
        } else if (item.priority[0].id == 5) {
          // Medio
          me.starts.forEach((el, index) => {
            if (index <= 1) {
              el.isActive = true;
            }
          });
        } else {
          // Básico
          me.starts.forEach((el, index) => {
            if (index <= 0) {
              el.isActive = true;
            }
          });
        }
      }
      // Super 5 estrellas1
      // Plus 4 estrellas2
      // Alto 3 3
      // Medio 2 4
      // Basico 1 5
    },
    textFarm(item) {
      let text = "";
      if (item.id == 1 || item.id == 2) {
        text = "Súper";
      } else {
        text = item.name;
      }
      return text;
    },
    loadVideo() {
      const me = this;
      // Obtén la etiqueta de video por su ID
      const video = document.getElementById("video-detail");
      // Obtén el elemento <source> dentro de la etiqueta de video
      const source = video?.querySelector("source");

      // Asigna la fuente original nuevamente
      source.src = me.record2.urlVideo;
      video?.load();
      video?.play();
    },
    toggleFullScreen() {
      const video = document.getElementById("video-detail");

      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        /* Firefox */
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        /* IE/Edge */
        video.msRequestFullscreen();
      }
    },
  },
  created() {
    const me = this;
    me.record2 = me.record;
  },
  mounted() {
    const me = this;
    me.fillStart(me.dataFarm);
    const cardId = document.getElementById("card" + me.record.id);
    cardId.classList.add("active-card-img");
    if (me.record2.type == 2) {
      me.loadVideo();
    }
  },
};
</script>

<style lang="css">
.card-carousel.active-card-img {
  border: 3px solid #f5ce35 !important;
}
.video-detail {
  max-height: 450px;
}

/*Media querys*/
/*** VERSION ESCRITORIO Y TABLETS PANTALLS GRANDE ***/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* .video-detail {
    height: 300px;
  } */
}
/*** VERSION TABLETS ***/
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* .video-detail {
    height: 300px;
  } */
}
/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  /* .video-detail {
    height: 300px;
  } */
}
</style>
